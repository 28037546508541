import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';

import {
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  ADMIN_PARTENAIRE_PAGE,
  SUPER_ADMIN_LIST_DOSSIER,
  CONTACT_PAGE,
  LOGIN_PAGE,
  LIST_RESERVATION,
  CONDITION_PAGE,
  BLOG_PAGE
} from 'settings/constant';
import { AuthContext } from 'context/AuthProvider';




const FooterMenu = () => {

  const {user} = useContext(AuthContext);

  var navigations = [
    {
      label: <NavLink to={HOME_PAGE}>Accueil</NavLink>,
      key: 'hotels',
    },
    {
      label: <NavLink to={LISTING_POSTS_PAGE}>Hôtels</NavLink>,
      key: 'listing',
    }
  ];
  
  const addLienIfNotExist = (lien,key) => {
    let existe = 0;
    navigations.forEach(element => {
        if(element.key === key){
          existe = 1;
        }
    });
    if(existe === 0) {
      navigations.push(lien);
    }
  }
  
  const supprimerLien = (key)=>{
  let tmpMenu = [];
  navigations.forEach( (element ,i) => {
    if(element.key !== key){
      tmpMenu.push(element);
    }
  });
  navigations = tmpMenu;
  }
  
  
  let lienSuperAdmin =  {
    label: <NavLink to={SUPER_ADMIN_LIST_DOSSIER}>Admin</NavLink>,
    key: 'super-admin',
  };

  let lienAdmPartenaire =  {
    label: <NavLink to={ADMIN_PARTENAIRE_PAGE}>Page Partenaire</NavLink>,
    key: 'admin-partenaire',
  };

  let lienConnexionUser =  {
    label: <NavLink to={LOGIN_PAGE} state={{type:"utilisateur"}}>Accès admin</NavLink>,
    key: 'connexion-user',
  };
  
  let withReservation = [
    {
      label: <NavLink to={LIST_RESERVATION}>Réservation</NavLink>,
      key: 'reservation',
    },
    {
      label: <NavLink to={CONTACT_PAGE}>Contact</NavLink>,
      key: 'agent',
    }
  ];

  let withoutReservation = [
    {
      label: <NavLink to={CONTACT_PAGE}>Contact</NavLink>,
      key: 'agent',
    }
  ]

  let nosBlogs = {
    label: <NavLink to={BLOG_PAGE}>Nos blogs</NavLink>,
    key: 'menu-4',
  }

 
  let rgpdNavigation =
    {
      label: <NavLink target='_blank' to={CONDITION_PAGE}>Conditions générales</NavLink>,
      key: 'rgpd',
    }

  if (user?.roles && user.roles.includes('ROLE_CLIENT')){
    navigations = navigations.concat(withReservation.filter(i => !navigations.some(j => i.key === j.key)));
  }
  else 
    navigations = navigations.concat(withoutReservation.filter(i => !navigations.some(j => i.key === j.key)));
    
  navigations.push(nosBlogs);
  
  // if(!user?.roles) {
  //     supprimerLien('super-admin');
  //     supprimerLien('admin-partenaire');
  //     addLienIfNotExist(lienConnexionUser,'connexion-user');
  // } else 
  if(user?.roles) {
    supprimerLien('connexion-user');
    if(user.roles.includes('ROLE_SUPER_ADMIN')  
    || user?.roles?.includes('ROLE_AGENT_COM')
    || user?.roles?.includes('ROLE_MANAGER')){
      addLienIfNotExist(lienSuperAdmin,'super-admin');
    }
    else if(user.roles.includes('ROLE_ADMIN_HOTEL')
    || user?.roles?.includes('ROLE_RECEP')){
      addLienIfNotExist(lienAdmPartenaire,'admin-partenaire');
    }
  }

  navigations.push(rgpdNavigation);
  return <Menu items={navigations} />;
};

export default FooterMenu;