import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import LogoArea, { TitleSlogan, TitleVersion, TitleSloganItalic } from './Logo.style';

const Logo = ({ className, withLink, linkTo, title, src, isItalic }) => {
  return (
    <LogoArea className={className}>
      {withLink ? (
        <NavLink to={linkTo} style={{ display: 'flex', flexDirection: 'column'}}>
          {src && <img style={{width: 128, height: 'auto'}} src={src} alt="TripFinder." />}
          {(title && isItalic) ? <TitleSloganItalic >{title}</TitleSloganItalic> : <TitleSlogan >{title}</TitleSlogan>}
        </NavLink>
      ) : (
        <Fragment>
          <div style={{ display: 'flex', flexDirection: 'column', width: "200px", alignItems: "center"}}>
            {src && <img style={{width: 128, height: 'auto'}} src={src} alt="TripFinder." />}
            {/* {title && <TitleVersion>{title}</TitleVersion>}  */}
          </div>
        </Fragment>
      )}
    </LogoArea>
  );
};

Logo.propTypes = {
  className: PropTypes.string,
  withLink: PropTypes.bool,
  src: PropTypes.string,
  title: PropTypes.string,
  linkTo: PropTypes.string,
};

export default Logo;
