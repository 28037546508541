import React, { useState, useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Sticky from 'react-stickynode';
import { IoIosClose } from 'react-icons/io';
import { Button, Drawer } from 'antd';
import Logo from 'components/UI/Logo/Logo';
import Navbar from 'components/Navbar/Navbar';
import { AuthContext } from 'context/AuthProvider';
import { LayoutContext } from 'context/LayoutProvider';
import useWindowSize from 'library/hooks/useWindowSize';
import { REDIRECT_APPLI, SLOGAN_MARESA } from 'settings/constant';
import AuthMenu from './AuthMenu';
import MainMenu from './MainMenu';
import MobileMenu from './MobileMenu';
import ProfileMenu from './ProfileMenu';
import NavbarSearch from './NavbarSearch';
import { FaDownload, FaPowerOff } from 'react-icons/fa';
import HeaderWrapper, {
  MobileNavbar,
  CloseDrawer,
  LogoArea,
} from './Header.style';
import { upperFirst } from 'lodash';

export default function Header() {
  let location = useLocation();
  const [{ searchVisibility }] = useContext(LayoutContext);
  // const []
  const { loggedIn,user } = useContext(AuthContext);
  const { width } = useWindowSize();
  const [state, setState] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const sidebarHandler = () => {
    setState(!state);
  };
  const headerType = location.pathname === '/' ? 'transparent' : 'default';


  const handleScroll = () => {
    if (window.pageYOffset > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  window.addEventListener('scroll', handleScroll);

  return (
    <HeaderWrapper>
      <Sticky
        top={headerType === 'transparent' ? -1 : 0}
        innerZ={10001}
        activeClass="isHeaderSticky"
      >
        {width > 1200 ? (
          <Navbar

            logo={
              <>
              {
                (scrolled || headerType == 'default'?
                  <Logo
                withLink
                linkTo="/"
                src="/images/logo-alt.png"
              />: 
              <Logo
                withLink
                linkTo="/"
                src="/images/logo-alt-white.png"
                title={SLOGAN_MARESA}
                isItalic={true}
              />
                )
              }
              </>
            }
            navMenu={<MainMenu />}
            authMenu={<AuthMenu />}
            isLogin={loggedIn}
            
            avatar={'FaPowerOff'}
            profileMenu={<ProfileMenu avatar={<FaPowerOff></FaPowerOff>} />}
            headerType={headerType}
            searchComponent={<NavbarSearch />}
            location={location}
            searchVisibility={searchVisibility}
          />
        ) : (
          <MobileNavbar className={headerType}>
            <LogoArea>
              <>
              {
                (scrolled || headerType == 'default'?
                  <Logo
                withLink
                linkTo="/"
                src="/images/logo-alt.png"
              />: 
              <Logo
                withLink
                linkTo="/"
                src="/images/logo-alt-white.png"
                title={SLOGAN_MARESA}
              />
                )
              }
              </>
            </LogoArea>
              <div style={{display:'flex', flexDirection:'row', gap:'20px', justifyContent:"flex-end"}}>
            {/*
                (scrolled || headerType == 'default'?
                <NavLink to={REDIRECT_APPLI}>
                  <span className='spanDowloadLink'>
                    <FaDownload style={{marginRight:'5px'}}/>
                    Appli
                    </span>
                </NavLink>:<></>
              )*/
              }

            <Button
              className={`hamburg-btn ${state ? 'active' : ''}`}
              // className={`hamburg-btn active`}
              onClick={sidebarHandler}
            > 
              <span />
              <span />
              <span />
            </Button>
            </div>
            <Drawer
              placement="right"
              closable={false}
              onClose={sidebarHandler}
              width="285px"
              className="mobile-header"
              open={state}
            >
              <CloseDrawer>
                { user?.nom && <span style={{marginTop:"15px", left:"25px", position:"absolute"}}>{upperFirst(user?.nom)} {user?.prenoms}</span> }
                <button onClick={sidebarHandler}>
                  <IoIosClose />
                </button>
              </CloseDrawer>
              {loggedIn ? (
                null
              ) : (
                <AuthMenu className="auth-menu" />
              )}
              
              <MobileMenu className="main-menu" />
            </Drawer>
          </MobileNavbar>
        )}
      </Sticky>
    </HeaderWrapper>
  );
}
